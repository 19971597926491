import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

// import heroBrandsImage from '../images/pages/brands/brands.png';
// import henColumnImage from '../images/pages/brands/hen.jpg';
// import stagColumnImage from '../images/pages/brands/stag.jpg';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const ourBrandsPage = ({ data }) => {
  const { pageTitle,
          metaTitle, 
          heroBackgroundImage,
          mainPageText, 
          leftColumnImage,
          leftColumnText,
          rightColumnImage, 
          rightColumnText, 
  } = data.contentfulOurBrands;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero-shallow" style={{backgroundImage:`url(${heroBackgroundImage.file.url})`}}>
        <div className="hero-overlay"></div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="hero-text-container">
                  <h1>{pageTitle}</h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main-description our-brands-page">
          <Container fluid={true} >
            <Row>
              <Col md={12}>
                <div className="full-width-layout">
                  {documentToReactComponents(mainPageText.json, options)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container fluid={true}>
            <div className="full-width-layout columns-wrapper">
              <Row className="row-eq-height">
                <Col md={6}>
                  <img alt="Hen Heaven" src={leftColumnImage.file.url} />
                  {documentToReactComponents(leftColumnText.json, options)}
                  <a target="_blank" rel="noopener noreferrer" className="main-button" href="https://www.henheaven.co.uk">www.henheaven.co.uk</a>
                </Col>
                <Col md={6}>
                  <img alt="The Stag Company" src={rightColumnImage.file.url} />
                  {documentToReactComponents(rightColumnText.json, options)}
                  <a target="_blank" rel="noopener noreferrer" className="main-button" href="https://www.thestagcompany.com">www.thestagcompany.com</a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
    </Layout>
  );
};

export default ourBrandsPage;

export const pageQuery = graphql`
  query {
    contentfulOurBrands {
      metaTitle
      pageTitle
      slug
      heroBackgroundImage {
        file {
          url
        }
      }
      mainPageText {
        json
      }
      leftColumnImage {
        file {
          url
        }
      }
      leftColumnText {
        json
      }
      rightColumnImage {
        file {
          url
        }
      }
      rightColumnText {
        json
      }
      updatedAt
    }
  }
`;